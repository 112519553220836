<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import NewListingTable from "@/components/listings/new-listing-table";
import InputGroup from "@/components/newforms/input-group";
import axios from "axios";
import { authHeader } from "../../../helpers/auth-header";
import ConfirmationModal from "@/components/modals/confirmation-modal";
import MessageModal from "@/components/modals/message-modal";
import SelectGroup from "@/components/newforms/select-group";
// import InputGroup2 from "@/components/forms/input-group";

export default {
    components: {
        Layout,
        PageHeader,
        NewListingTable,
        InputGroup,
        ConfirmationModal,
        MessageModal,
        SelectGroup
    },
    data() {
        return {
            title: "Agency",
            items: [
                {
                    text: "Agency"
                },
            ],
            fields: [
                {
                    key: 'id',
                    label: "ID",
                    sortable: true
                },
                {
                    key: 'agency_name',
                    label: "Agency Name",
                    sortable: true
                },
                {
                    key: 'agent_count',
                    label: "No. of Agents",
                    sortable: true
                },
                {
                    key: 'balance',
                    label: "Points",
                    sortable: true
                },
                {
                    key: 'is_verified',
                    label: "Verification Status",
                    sortable: true
                },
                { key: "action" },
            ],
            filter: {
                "agency_name": "",
                "balance_greater": "",
                "balance_less": "",
                "id": "",
                "is_verified": "",
            },
            errors: {},
            verify_options: [
                { text: 'All', value: "" },
                { text: 'Verified', value: 1 },
                { text: 'Not Verified', value: 0 },
            ],
        };
    },
    computed: {

    },
    watch: {

    },
    async mounted() {
    },
    methods: {
        filterTable() {
            this.$refs.list_table.loadTable(1);
        },
        resetTable() {
            this.filter.agency_name = '';
            this.filter.balance_greater = '';
            this.filter.balance_less = '';
            this.filter.id = '';
            this.filter.is_verified = '';
            this.$refs.list_table.loadTable(1);
        },
        deleteRecord(id) {
            const deleteCallback = async () => {
                await axios.delete(`${process.env.VUE_APP_APIURL}/agency/${id}`, {
                    headers: authHeader(),
                })
                .then((e) => {
                    if (e.status == 204) {
                        this.$refs.confirmationModal.closeModal();
                        this.$refs.messageModal.showModal('The record has been deleted');
                        this.$refs.list_table.loadTable(1);
                    }
                });
            }
            this.$refs.confirmationModal.showModal(`Confirm delete admin (ID: ${id})?`, deleteCallback);
        },
    }
};
</script>

<template>
    <Layout>
        <MessageModal ref="messageModal" />
        <ConfirmationModal ref="confirmationModal" />
        <PageHeader :title="title" :items="items"/>
        <b-card class="mb-2" style="border-radius: 15px;">
            <div class="row">
                <div class="col-sm-12 col-md-4 col-lg-3 mt-2">
                    <InputGroup id="balance_greater" name="balance_greater" v-model="filter.balance_greater" label="Point Greater Than" placeholder="Enter points range"/>
                    <InputGroup id="balance_less" name="balance_less" v-model="filter.balance_less" label="Point Smaller Than" placeholder="Enter points range" class="mt-3"/>
                </div>
                <div class="col-sm-12 col-md-4 col-lg-3 mt-2">
                    <InputGroup id="agency_name" name="agency_name" v-model="filter.agency_name" label="Agency Name" :onEnter="filterTable" placeholder="Enter Agency Name"/>
                </div>
                <div class="col-sm-12 col-md-4 col-lg-3 mt-2">
                    <InputGroup id="id" name="id" v-model="filter.id" label="Agency ID" placeholder="Enter Agency ID"/>
                </div>
                <div class="col-sm-12 col-md-4 col-lg-3 mt-2">
                    <SelectGroup id="is_verified" name="is_verified" v-model="filter.is_verified" label="Verified" placeholder="Verified Options" :options="verify_options" :default_value="false"/>
                </div>
            </div>
            <div class="row mb-3">
                <div class="col-12 d-flex justify-content-end" style="margin-top: 12px;">
                    <button @click="filterTable" class="btn btn-filter">Filter</button>
                    <button @click="resetTable" class="btn btn-orange" style="margin-left: 11px;" >Reset</button>
                </div>
            </div>
        <!-- </b-card>
        <b-card> -->
            <NewListingTable :entries="parseInt(10)" :filter="filter" :fields="fields" path="agency-list" ref="list_table">
                <template v-slot:cell(action)="{ item }">
                    <router-link
                    class="mr-2 grey-icon-color"
                    :to="{ name: 'Agency View', params: { id: item.id } }">
                        <i class="ri-eye-line font-size-18" v-b-tooltip.hover title="View"></i>
                    </router-link>
                    <router-link class="mr-2 grey-icon-color" :to="{name: 'Agency Form', params: {id: item.id}}"><i class="ri-pencil-line font-size-18" v-b-tooltip.hover title="Edit"></i></router-link>
                    <a href="javascript:void(0);" class="text-danger" v-b-tooltip.hover title="Delete"
                        @click="deleteRecord(item.id)">
                        <i class="ri-delete-bin-line font-size-18"></i>
                    </a>
                </template>

                <!-- <template v-slot:cell(is_verified)="{ item }">
                    <b-badge :variant="parseInt(item.is_verified) == 1 ? 'success' : 'danger' ">{{ parseInt(item.is_verified) == 1 ? 'Verified' : "Not Verified" }}</b-badge>
                </template> -->
                <template v-slot:cell(is_verified)="row">
                    <div class="badge font-size-12" :class="{
                        'badge-danger': parseInt(`${row.value}`) === 0,
                        'badge-success': parseInt(`${row.value}`) === 1,
                    }">{{ parseInt(row.value) === 1 ? 'Verified' : 'Not Verified' }}</div>


                </template>
            </NewListingTable>
        </b-card>
    </Layout>
</template>